function Facebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_5572_22488)">
        <path
          d="M22.2973 23.6001C23.0165 23.6001 23.5997 23.0169 23.5997 22.2975V1.30271C23.5997 0.583231 23.0166 0.000190735 22.2973 0.000190735H1.30251C0.583058 0.000190735 0 0.583231 0 1.30271V22.2975C0 23.0168 0.582951 23.6001 1.30251 23.6001H22.2973Z"
          fill={props.color || 'black'}
        />
        <path
          d="M16.2836 23.6001V14.4609H19.3512L19.8105 10.8992H16.2836V8.62529C16.2836 7.59409 16.5699 6.89137 18.0486 6.89137L19.9347 6.89054V3.70493C19.6085 3.66152 18.4889 3.56455 17.1864 3.56455C14.4671 3.56455 12.6055 5.22439 12.6055 8.27255V10.8992H9.53003V14.4609H12.6055V23.6001H16.2836Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5572_22488">
          <rect width="23.6" height="23.6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Facebook;
