function Filter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <line x1="14" y1="5" x2="2" y2="5" stroke={props.color} strokeWidth="2" />
      <line
        x1="14"
        y1="11"
        x2="2"
        y2="11"
        stroke={props.color ?? 'black'}
        strokeWidth="2"
      />
      <circle cx="6" cy="5" r="2" fill={props.color} />
      <circle cx="10" cy="11" r="2" fill={props.color} />
    </svg>
  );
}

export default Filter;
