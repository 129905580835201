function LastPageArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <line
        x1="12"
        y1="13"
        x2="12"
        y2="3"
        stroke={props.color}
        strokeWidth="2"
      />
      <path d="M4 4L8 8L4 12" stroke={props.color} strokeWidth="2" />
    </svg>
  );
}

export default LastPageArrow;
