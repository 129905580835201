function LeftArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.56249 8.00033L10.6406 12.2321L9.36026 13.7686L3.50044 8.88537L3.50044 7.11529L9.36026 2.23211L10.6406 3.76855L5.56249 8.00033Z"
        fill={props?.color}
      />
    </svg>
  );
}

export default LeftArrow;
