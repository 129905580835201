import React from 'react';

function AirmeeSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 62 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#a)" fill="currentColor">
        <path d="M0 4.14v2.838h4.857c-.735.884-1.962 2.432-2.77 3.402-.41.493-.819.75-1.47.75H0V14h.592c1.849 0 2.622-.417 4.39-2.616L6.85 9.06V14h2.77V4.14H0ZM13.766 4.14h-3.209V14h3.21V4.14ZM13.833 0H10.49v3.184h3.342V0ZM33.201 10.6l-1.203-6.46H26.86V14h3.21V7.574L31.363 14H35l1.295-6.466V14h3.209V4.14h-5.231L33.2 10.6ZM40.438 14h10.29v-2.858h-7.08v-1.029h7.063v-2.21h-7.063v-.918h7.08V4.141h-10.29V14ZM51.662 14H61.95v-2.858h-7.08v-1.029h7.065v-2.21H54.87v-.918h7.08V4.141H51.66V14ZM22.335 7.921h-4.428v-.95h4.428c.344 0 .562.078.562.477 0 .38-.227.473-.561.473Zm3.77-.507V7.09c0-2.072-1.385-2.948-3.324-2.948h-8.084V14h3.21v-3.482h.39s.39.493.952 1.182c1.467 1.8 2.456 2.3 4.133 2.3h2.471v-2.864h-2.345c-.663 0-.932-.037-1.453-.617h.498c2.439-.005 3.553-1.04 3.553-3.105Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h61.95v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AirmeeSvg;
