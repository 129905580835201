import React from 'react';

function GreenCheckmarkSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="8" cy="8" r="6" fill="#009A44" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2139 5L7.188 10.0996L4.68491 7.81672L4 8.44136L6.80551 11H7.67068L12 5.51616L11.2139 5Z"
        fill="white"
      />
    </svg>
  );
}

export default GreenCheckmarkSvg;
