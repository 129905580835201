function Dot(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="3" cy="3" r="3" fill={props?.color} />
    </svg>
  );
}

export default Dot;
