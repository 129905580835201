function ShoppingBag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        x="3"
        y="8"
        width="18"
        height="13"
        stroke={props.color ?? 'black'}
        strokeWidth="2"
      />
      <rect
        x="1"
        y="-1"
        width="10"
        height="5"
        transform="matrix(1 0 0 -1 6 7)"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
}

export default ShoppingBag;
