import React from 'react';

function GreenPlusmark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="12" cy="12" r="10" fill="#009A44" />
      <line x1="12" y1="6" x2="12" y2="18" stroke="white" strokeWidth="2" />
      <line x1="18" y1="12" x2="6" y2="12" stroke="white" strokeWidth="2" />
    </svg>
  );
}

export default GreenPlusmark;
