function Norway(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_5831_87684)">
        <path d="M0 0H18V18H0V0Z" fill="#ED2939" />
        <path d="M4.5 0H9V18H4.5V0Z" fill="white" />
        <path d="M0 6.75H18V11.25H0V6.75Z" fill="white" />
        <path d="M5.625 0H7.875V18H5.625V0Z" fill="#002664" />
        <path d="M0 7.875H18V10.125H0V7.875Z" fill="#002664" />
      </g>
      <defs>
        <clipPath id="clip0_5831_87684">
          <rect width="18" height="18" rx="9" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Norway;
