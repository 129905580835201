function Sweden(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_5831_87681)">
        <rect width="18" height="18" rx="9" fill="white" />
        <path d="M0 0H18V18H0V0Z" fill="#005293" />
        <path
          d="M4.71094 0V7.2H0V10.8H4.71094V18H8.31094V10.8H18V7.2H8.31094V0H4.71094Z"
          fill="#FECB00"
        />
      </g>
      <defs>
        <clipPath id="clip0_5831_87681">
          <rect width="18" height="18" rx="9" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Sweden;
