import React from 'react';

function PostnordSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 74 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M56.136 7c0 3.316-2.683 4.737-5.16 4.737-2.476 0-5.056-1.369-5.056-4.58 0-3.315 2.632-4.736 5.16-4.736S56.136 3.79 56.136 7Zm-3.354.105c0-1.105-.722-1.842-1.754-1.842-1.083 0-1.754.737-1.754 1.842 0 1.053.722 1.79 1.754 1.79 1.084 0 1.754-.737 1.754-1.79Zm-42.824 0c0 2.527-1.703 4.632-4.386 4.632-1.65 0-2.425-.842-2.425-.842V14H0V2.684h3.199v.842s.825-1.158 2.631-1.158c2.632.053 4.128 2.106 4.128 4.737Zm-3.354 0c0-1.105-.722-1.842-1.754-1.842-1.084 0-1.754.737-1.754 1.842 0 1.053.722 1.79 1.754 1.79 1.084 0 1.754-.737 1.754-1.79Zm53.505.158c0-1.316.774-1.737 1.857-1.737.671 0 1.084.158 1.084.158l.103-3s-.206-.105-.774-.105c-1.754 0-2.27 1.158-2.27 1.158V2.684H56.91v8.79h3.199v-4.21ZM73.111 0v11.474h-3.199v-.842s-.825 1.158-2.631 1.158c-2.632 0-4.128-2.106-4.128-4.685 0-2.526 1.703-4.631 4.386-4.631 1.65 0 2.425.842 2.425.842V.684L73.11 0Zm-3.096 7.053c0-1.053-.722-1.79-1.754-1.79-1.083 0-1.754.737-1.754 1.79 0 1.105.722 1.842 1.754 1.842 1.084 0 1.754-.737 1.754-1.842ZM42 2.42c-1.858 0-2.528 1.158-2.528 1.158v-.895h-3.2v8.79h3.2V6.947c0-1.105.413-1.631 1.393-1.631s1.135.842 1.135 1.895v4.263h3.199V6.105c-.052-2.42-1.187-3.684-3.2-3.684ZM33.073.737h-3.2v10.737h3.2V5.42h1.496l1.445-2.737h-2.941V.737Zm-7.12 5.158c-1.032-.158-1.497-.158-1.497-.58 0-.368.413-.473 1.239-.473 1.393 0 2.631.474 2.631.474l.568-2.474s-1.342-.42-3.148-.42c-3.044-.001-4.643 1.21-4.643 3.104 0 1.474.877 2.369 3.353 2.685 1.084.157 1.445.21 1.445.578 0 .369-.413.527-1.135.527-1.6 0-3.096-.684-3.096-.684l-.619 2.526s1.496.526 3.457.526c3.25 0 4.798-1.158 4.798-3.105-.051-1.474-.825-2.316-3.353-2.684ZM20.74 7c0 3.316-2.683 4.737-5.16 4.737-2.476 0-5.056-1.369-5.056-4.58 0-3.315 2.632-4.736 5.16-4.736 2.477 0 5.056 1.368 5.056 4.579Zm-3.405.105c0-1.105-.722-1.842-1.754-1.842-1.084 0-1.754.737-1.754 1.842 0 1.053.722 1.79 1.754 1.79 1.083 0 1.754-.737 1.754-1.79Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default PostnordSvg;
