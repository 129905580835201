function RightArrowWithTail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M13 8H2" stroke={props.color || 'black'} strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6527 7.99991L7 3.76813L8.17309 2.23169L14.0001 7.53154V8.46829L8.17309 13.7681L7 12.2317L11.6527 7.99991Z"
        fill={props.color || 'black'}
      />
    </svg>
  );
}

export default RightArrowWithTail;
