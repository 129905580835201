function CarrotSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99991 5.562L3.76813 10.6401L2.23169 9.35977L7.11487 3.49995L8.88495 3.49995L13.7681 9.35977L12.2317 10.6401L7.99991 5.562Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default CarrotSvg;
