function DownArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99984 10.438L12.2316 5.35986L13.7681 6.64023L8.88489 12.5L7.1148 12.5L2.23162 6.64023L3.76807 5.35986L7.99984 10.438Z"
        fill={props.color}
      />
    </svg>
  );
}

export default DownArrow;
