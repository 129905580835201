import React from 'react';

function AirmeeSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle
        cx="10"
        cy="10"
        r="7"
        stroke={props.color || 'black'}
        strokeWidth="2"
      />
      <path d="M15 15L21 21" stroke={props.color || 'black'} strokeWidth="2" />
    </svg>
  );
}

export default AirmeeSvg;
