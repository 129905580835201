import React from 'react';

function RadioCheckedSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7.5" stroke="black" />
      <circle cx="8" cy="8" r="6" fill="black" />
    </svg>
  );
}

export default RadioCheckedSvg;
