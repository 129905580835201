import React from 'react';

function LannaSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 64 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0 0h1.746v12.254H9.59V14H0V0ZM18.37 0h-2.957l-4.845 14h1.747l1.453-4.29h6.218L21.439 14h1.807L18.371 0Zm-4.077 8.115 2.16-6.369h.828l2.16 6.37h-5.148ZM22.459 0h-1.747v1.746h1.747V0ZM13.06 0h-1.747v1.746h1.747V0ZM24.206 0h3.099l6.803 12.254h.495V0h1.746v14H33.25L26.447 1.746h-.495V14h-1.746V0ZM38.083 0h3.099l6.803 12.254h.495V0h1.746v14h-3.099L40.324 1.746h-.495V14h-1.746V0ZM59.007 0h-2.958l-4.845 14h1.746l1.454-4.29h6.217L62.075 14h1.807L59.007 0Zm-4.078 8.115 2.16-6.369h.827l2.14 6.37H54.93Z"
        fill="#000"
      />
    </svg>
  );
}

export default LannaSvg;
