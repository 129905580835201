function RightArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.438 7.99991L5.35986 3.76813L6.64023 2.23169L12.5 7.11487V8.88495L6.64023 13.7681L5.35986 12.2317L10.438 7.99991Z"
        fill={props?.color}
      />
    </svg>
  );
}

export default RightArrow;
