function UpArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.56205L3.76822 10.6402L2.23178 9.35981L7.11496 3.5L8.88504 3.5L13.7682 9.35981L12.2318 10.6402L8 5.56205Z"
        fill={props.color}
      />
    </svg>
  );
}

export default UpArrow;
