import React from 'react';

function RadioUncheckedSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" fill="white" />
      <circle cx="8" cy="8" r="7.5" stroke="#757575" />
    </svg>
  );
}

export default RadioUncheckedSvg;
