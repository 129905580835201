import React from 'react';

export default function HamburgerSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 10L2 10L2 8L22 8V10Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 16L2 16L2 14L22 14V16Z"
        fill="black"
      />
    </svg>
  );
}
