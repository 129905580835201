function FirstPageArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <line x1="4" y1="3" x2="4" y2="13" stroke={props.color} strokeWidth="2" />
      <path d="M12 12L8 8L12 4" stroke={props.color} strokeWidth="2" />
    </svg>
  );
}

export default FirstPageArrow;
