function Cross(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M3 3L8 8L3 13" stroke={props.color || 'black'} strokeWidth="2" />
      <path
        d="M13 3L8 8L13 13"
        stroke={props.color || 'black'}
        strokeWidth="2"
      />
    </svg>
  );
}

export default Cross;
